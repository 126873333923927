<template>
  <section class="mfo__banner">
    <img
        class="mfo__banner-img"
        src="@/assets/magento/magento-modules-customization/magento-modules-customization@2x.jpg"
        alt="Third party modules customization"
        loading="lazy"
        modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
    />
    <h1 class="mfo__banner-title">
      Third Party Modules Customization
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true">
    <p>Are you looking for a Magento module that suits  your needs?  While Magento offers a wide range of 3rd party modules, custom solutions can help to thoroughly upgrade your web store and find the right balance between general requirements and the individual needs of your eCommerce brand.</p>
    <p>We offer Magento custom 3rd party modules development so you can get the maximum functionality which is compatible with your platform. A customized module connects to your 3rd party software, as well as the  internal structure of your website. Our team of professional developers has years of collective experience creating custom modules for CRM, shipping & delivery options, payment gateways, and any other functions you may require.</p>
    <p>It's also worth mentioning that we work as a custom development team for a number of well-known Magento extensions vendors.  We operate under a white-label with some of them; while for others, we work as development partners. The key-note here is that having worked on custom development requests for the extensions of many vendors through the years, we have been provided with a vast and expansive knowledge regarding the pros and cons of many extensions, and we are most definitely the right people to consult about which extensions are worthy of being used, and which are better left alone.</p>
  </section-layout>

  <section-layout name="mfo__design" title="3rd Party Module design" margin="true">
    <p>The WDevs team analyzes your initial request, discusses various ideas with you & develops a unique design based on our knowledge & your business values. By having a modern attractively designed Magento module, you can significantly improve your customers’ online shopping experience.</p>
  </section-layout>

  <section-layout name="mfo__development-customization" title="3rd Party Module Development & Customization" margin="true">
    <p>We understand your need to stand out from your competitors during the process of achieving  success in your industry, and that’s why we believe that your eCommerce store should look different too. All you need to do is share your vision of the necessary functionality with us, and our team will deliver the best possible solution according to your request. We can add-on or modify the functions to perfectly match the module to your website.</p>
  </section-layout>

  <section-layout name="mfo__integration" title="3rd Party Module Integration Support & Troubleshooting" margin="true">
    <p>We provide full support for the Magento 3rd Party Module development & integration process, including regular updates. After we deploy the module on your website, we offer technical support services to guarantee everything works as it was planned. During maintenance, our devs check the module performance to eliminate any issues or bugs, and make a customer’s stay on your website both pleasant & comfortable.</p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img,
    picture {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 72px;
      max-width: 1150px;
    }
  }

  &__first,
  &__design,
  &__development-customization,
  &__integration {
    .section_content {
      display: block;
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>
